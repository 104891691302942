import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {flightsFearQuoteFxn} from "../flightSearch/actions";
import {Modal} from "../../components/Elements";
import RegisterBanner from "../../components/pageComponents/registerBanner";
import {useLocation, useNavigate} from "react-router-dom";
import ChangePrice from "../ModalComponent/changePrice";
import OneWayBooking from "./oneWayBooking";
import RoundTripBooking from "./roundTripBooking";
import OverseasBooking from "./overseasBooking";
import {bookSeatsFxn} from "../ticketList/action";
import {calculateNewFareWithMargins, updateSingleObjectPriceUpdate} from "../../components/utils/appUtils";
import MultiWayBooking from "./multiWayBooking";

let {notifyModal} = Modal
const contactInitState = {
    email: "",
    contactNo: ""
}
const Checkout = (props) => {
    const [bookingType, setBookingType] = useState("Outbound")
    const [ssrResponse, setSsrResponse] = useState({});
    const [sessionId, setSessionId] = useState("");
    const navigate = useNavigate();

    const {flightObj, traceId, selectedFlight, fareQuoteObj, flightBookingType} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj,
        selectedFlight: state.flightsReducer.selectedFlight,
        traceId: state.flightsReducer.traceId,
        fareQuoteObj: state.flightsReducer.fareQuoteObj,
        flightBookingType: state.flightsReducer.flightBookingType,
    }))
    const location = useLocation();
    const stateParams = location.state?.params;
    useEffect(() => {
        if (stateParams && stateParams.bookingType) {
            setBookingType(stateParams.bookingType)
        }
        if (stateParams && stateParams.sessionId) {
            setSessionId(stateParams.sessionId)
        }
    }, [stateParams])
    const [state, setState] = useState(contactInitState)
    let dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(1)
    const [fareObject, setFareObject] = useState({})
    const [bookingFlightData, setBookingFlightData] = useState({})
    const [visiblePassportFields, setVisiblePassportFields] = useState(false);


    const [fareState, setFareState] = useState({
        oldFare: {},
        newFare: {},
        quoteFare: {},
        visible: false
    })


    useEffect(() => {
        if (bookingType === "Inbound") {
            dispatch({type: "RESET_SSR_RESPONSE"});
            setSsrResponse({})
        }
        events.fareQuote();
    }, [traceId, selectedFlight, bookingType])


    const events = {
        goToSearch: () => {
            navigate('/flight-search')
        },
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        updateVisiblePassportField: (flightDoc) => {
            let {Segments} = flightDoc;
            const originCountry = Segments?.[0]?.[0]?.Origin?.Airport?.CountryName;
            const firstSegment = Segments?.[0];
            const destinationCountry = firstSegment?.[firstSegment.length - 1]?.Destination?.Airport?.CountryName;
            if (originCountry && destinationCountry) {
                setVisiblePassportFields(originCountry !== destinationCountry);
            }
        },
        fareQuote: async () => {
            if (selectedFlight.mappedOutBoundFlights) {
                let bookingTypeValue = bookingType
                if (location.state && location.state.params && location.state.params.bookingType) {
                    bookingTypeValue = location.state.params.bookingType;
                }
                let bookingFlightData = bookingTypeValue == "Inbound" ? selectedFlight['mappedInBoundFlights'] : selectedFlight['mappedOutBoundFlights'];
                if (bookingFlightData) {
                    events.updateVisiblePassportField(bookingFlightData)
                    if (traceId && bookingFlightData['ResultIndex']) {
                        let obj = {
                            traceId: traceId,
                            resultIndex: bookingFlightData['ResultIndex'],
                            bookingTypeValue
                        }
                        const {data, success, ssrResponse, message} = await dispatch(flightsFearQuoteFxn(obj));
                        console.log(data, 'data ----------')
                        if (success) {
                            events.checkPriceDifferent(data, obj, bookingFlightData);
                            setSsrResponse(ssrResponse);
                            dispatch({type: "SET_SSR_RESPONSE", ssrResponse: ssrResponse});
                        } else {
                            notifyModal({message: message, onClose: events.goToSearch})
                        }
                    }
                }
            } else {
                // console.log(traceId, selectedFlight['ResultIndex'], "selectedFlight['ResultIndex']");
                if (traceId && selectedFlight['ResultIndex']) {
                    events.updateVisiblePassportField(selectedFlight)
                    // console.log('if part');
                    let obj = {
                        traceId: traceId,
                        resultIndex: selectedFlight['ResultIndex'],
                        bookingTypeValue: bookingType
                    }
                    const {data, success, ssrResponse, message} = await dispatch(flightsFearQuoteFxn(obj));
                    console.log(data, 'data -----single-----')

                    if (success) {
                        events.checkPriceDifferent(data, obj, selectedFlight);
                        setSsrResponse(ssrResponse);
                        dispatch({type: "SET_SSR_RESPONSE", ssrResponse: ssrResponse});
                    } else {
                        notifyModal({message: message, onClose: events.goToSearch})
                    }
                }
            }
        },
        checkPriceDifferent: async (data, obj, flightData) => {
            let marginData = {
                xTravelExchangeMargin: data.xTravelExchangeMargin,
                agentXTravelMargin: data.agentXTravelMargin
            }
            data = await updateSingleObjectPriceUpdate(data, marginData)
            flightData = await updateSingleObjectPriceUpdate(flightData, marginData)
            let {Fare: quoteFare, FareBreakdown} = data;
            const ootTaxTotal = FareBreakdown.reduce((sum, item) => sum + (Number(item.ootTaxTotal || 0) || 0), 0) || 0;

            const newPrice = quoteFare?.newFare + ootTaxTotal
            if (newPrice == flightData?.Fare?.newFare) {
                dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data})
                setFareObject(data);
            } else {
                dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data})
                setFareState({
                    oldFare: flightData?.Fare,
                    newFare: quoteFare,
                    quoteFare: data,
                    visible: true
                })
            }
        },
        getBookSeatsFxn: async (obj) => {
            const {data} = await dispatch(bookSeatsFxn(obj));
            dispatch({type: "SET_SSR_RESPONSE", ssrResponse: data});
            setSsrResponse(data);
        },
        closeChangePriceModal: (data) => {
            dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: selectedFlight})
            setFareState({
                oldFare: selectedFlight?.Fare,
                newFare: selectedFlight?.Fare,
                quoteFare: selectedFlight,
                visible: false,
            });
            navigate('/flight-search-result')
        },
        submitChangePriceModal: (data) => {
            // console.log(data, "Dattattttttttt")
            setFareObject(data);
            dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data});
            setFareState({
                oldFare: {},
                newFare: {},
                quoteFare: {},
                visible: false,
            });
        },
        addPassenger: () => {
            setActiveTab(2)
            window.scrollTo(0, 0)
        },


    }


    // console.log(fareState, "farestate=--------------")

    return (
        <div>


            {flightObj.journeyType == "2" ?
                <>
                    {flightBookingType == "overseasFlight" ?
                        <OverseasBooking flightObj={flightObj} traceId={traceId} selectedFlight={selectedFlight}
                                         fareObject={fareObject}
                                         bookingType={bookingType}
                                         ssrResponse={ssrResponse}
                                         currentUser={props.user}
                                         visiblePassportFields={visiblePassportFields}
                                         flightBookingType={flightBookingType}
                                         fareQuoteObj={fareQuoteObj}/> :
                        <RoundTripBooking flightObj={flightObj} traceId={traceId} selectedFlight={selectedFlight}
                                          fareObject={fareObject}
                                          ssrResponse={ssrResponse}
                                          currentUser={props.user}
                                          bookingSessionId={sessionId}
                                          visiblePassportFields={visiblePassportFields}
                                          bookingType={bookingType}
                                          flightBookingType={flightBookingType}
                                          fareQuoteObj={fareQuoteObj}/>}
                </> : null}

            {flightObj.journeyType == "1" ?
                <div>
                    <OneWayBooking
                        flightObj={flightObj}
                        traceId={traceId}
                        currentUser={props.user}
                        ssrResponse={ssrResponse}
                        selectedFlight={selectedFlight}
                        visiblePassportFields={visiblePassportFields}
                        fareObject={fareObject}
                        flightBookingType={flightBookingType}
                        fareQuoteObj={fareQuoteObj}/>
                </div> : null}

            {flightObj.journeyType == "3" ?
                <div>
                    <MultiWayBooking
                        flightObj={flightObj}
                        traceId={traceId}
                        currentUser={props.user}
                        ssrResponse={ssrResponse}
                        selectedFlight={selectedFlight}
                        visiblePassportFields={visiblePassportFields}
                        fareObject={fareObject}
                        flightBookingType={flightBookingType}
                        fareQuoteObj={fareQuoteObj}/>
                </div> : null}

            <RegisterBanner/>
            {fareState.visible ?
                <ChangePrice {...fareState}
                             onSubmit={events.submitChangePriceModal}
                             onClose={events.closeChangePriceModal}/> : null}
        </div>
    );
}

export default Checkout
