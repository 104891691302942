import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {airLinesFxn} from "../actions";
import {FlightDatePicker, Form, notification, Radio, Select, Tooltip} from "../../../components/Elements";
import {CabinComponent, FareTypesComponent, PassengerComponent} from "./flightSearchComponent";
import moment from "moment";
import {displayDateOnly, fareTypeList} from "../../../components/utils/appUtils";
import {swapIcon} from "../../../components/imgComp"
import {FareTypesNewComponent} from "./flightSearchComponent";
import SelectCityComponent from "./SelectCityComponent";
import TravellersComponent from "./travellersComponent";
import {calendarFareFxn} from "../../ticketList/action";
import ChooseDateComponent from "./ChooseDateComponent";
import DeleteIcon from '../../../assets/img/delete.png'

const MultiCitySearchComponent = (props) => {
    let {journeyType, onClick, flightObj, state, setState, changeJourneyType} = props;
    let dispatch = useDispatch()
    let [airlineList, setAirlineList] = useState([])
    const [calendarFareData, setCalendarFareData] = useState({
        departure: [],
        return: []
    });
    const [segments, setSegments] = useState([
        { origin: "", originCity: "", originAirportName: "", destination: "", destinationCity: "", destinationAirportName: "", preferredDepartureTime: moment() },
        { origin: "", originCity: "", originAirportName: "", destination: "", destinationCity: "", destinationAirportName: "", preferredDepartureTime: null }
    ]);

    const addSegment = () => {
        if (segments.length < 7) {
            const lastSegment = segments[segments.length - 1];
            setSegments([...segments, {
                origin: lastSegment.destination,
                originCity: lastSegment.destinationCity,
                originAirportName: lastSegment.destinationAirportName,
                destination: "",
                destinationCity: "",
                destinationAirportName: "",
                preferredDepartureTime: ""
            }]);
        }
    };

    const removeLastSegment = () => {
        if (segments.length > 2) {
            setSegments(segments.slice(0, -1));
        }
    };
    const events = {
        _updateSegmentState: (index, data) => {
            setSegments((prevSegments) => {
                const updatedSegments = [...prevSegments];
                updatedSegments[index] = { ...updatedSegments[index], ...data };

                if (data.destination && index < updatedSegments.length - 1) {
                    updatedSegments[index + 1] = {
                        ...updatedSegments[index + 1],
                        origin: data.destination,
                        originCity: data.destinationCity,
                        originAirportName: data.destinationAirportName
                    };
                }
                return updatedSegments;
            });
        },
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        _choosePreferredDepartureTime: (value) => {
            let obj = {preferredDepartureTime: value}
            if (obj.preferredReturnTime) {
                if (moment(obj.preferredReturnTime).isBefore(value)) {
                    obj.preferredReturnTime = value
                }
            } else {
                if (journeyType !== "1") {
                    obj.preferredReturnTime = value
                }
            }
            events._updateState(obj)
        },
        _choosePreferredReturnTime: () => {
            if (!state.preferredReturnTime) {
                events._updateState({preferredReturnTime: state.preferredDepartureTime})
            }
        },
        loadAirLine: async () => {
            let params = {
                results: 10000,
                count: 10000,
            }
            let {data} = await airLinesFxn(params);
            setAirlineList(data)
        },
        handleSearchFlight: async () => {
            // if (!state.origin) {
            //     return notification.warning({message: "Please choose Departing From."})
            // }
            // if (!state.destination) {
            //     return notification.warning({message: "Please choose Going To."})
            // }
            // if (!state.preferredDepartureTime) {
            //     return notification.warning({message: "Please choose Departure Date."})
            // }
            if (state.adultCount == 0 && state.childCount == 0 && state.infantCount == 0) {
                return notification.warning({message: "Please choose Passenger."})
            }
            let obj = {
                ...state, journeyType, Segments: segments
            }
            // await events.getCalendarFare(obj)
            dispatch({type: "SEARCH_FLIGHT", flightObj: obj})
            onClick(obj)
        },
        getCalendarFare: async (date, label = 'Departure') => {
            // console.log('from callback----', date, "label", label)
            let firstDayOfMonth = moment(date).startOf('month');

            if (firstDayOfMonth < moment()) {
                firstDayOfMonth = moment(date);
            }

            let obj = {
                origin: state?.origin,
                destination: state?.destination,
                cabinClass: state?.cabinClass,
                preferredDepartureTime: firstDayOfMonth,
                journeyType: journeyType
            };

            let objReturn = {
                origin: state?.destination,
                destination: state?.origin,
                cabinClass: state?.cabinClass,
                preferredDepartureTime: firstDayOfMonth,
                journeyType: journeyType
            };

            let valData = label === 'Return' ? objReturn : obj;
            let resp = await dispatch(calendarFareFxn(valData));

            if (resp?.data?.SearchResults) {
                setCalendarFareData((prevState) => {
                    return {
                        ...prevState,
                        [label.toLowerCase()]: [
                            ...prevState[label.toLowerCase()] || [],
                            ...resp.data.SearchResults,
                        ]
                    };
                });
            }
        },
        callbackCalendarFxn: async (date, label) => {
            let nextMonth = moment(date).add(1, 'month');

            await Promise.all([
                events.getCalendarFare(date, label),
                events.getCalendarFare(nextMonth, label)
            ]);
        },
    }
    useEffect(() => {
        // events.getCalendarFare()
    }, [])
    useEffect(() => {
        if (flightObj) {
            if (flightObj.preferredDepartureTime) {
                flightObj.preferredDepartureTime = moment(flightObj.preferredDepartureTime)
            }
            if (flightObj.preferredReturnTime) {
                flightObj.preferredReturnTime = moment(flightObj.preferredReturnTime)
            }
            setState((prevState) => {
                return {
                    ...prevState,
                    ...flightObj
                }
            })
        }
    }, [flightObj])

    useEffect(() => {
        if (journeyType == "1") {
            events._updateState({preferredReturnTime: null})
        } else {
            events._updateState({preferredReturnTime: state.preferredDepartureTime})
        }
    }, [journeyType])

    const disabledDate = (current, fromDate) => {
        return current && moment(current).isBefore(fromDate, 'day');
    };


    useEffect(() => {
        setCalendarFareData({departure: [], return: []});
        const loadDefaultCalendarFares = async () => {
            let currentDate = moment();
            let nextMonth = moment().add(1, 'month');

            await Promise.all([
                events.getCalendarFare(currentDate, 'Departure'),
                events.getCalendarFare(nextMonth, 'Departure'),
                // events.getCalendarFare(currentDate, 'Return'),
                // events.getCalendarFare(nextMonth, 'Return')
            ]);
        };

        loadDefaultCalendarFares();
    }, [state.origin, state.destination]);


    return (
        <>
            <Form>
                <div className="find-flight search-flight-main-box">
                    <div className="flight-top-filters">
                        <div className="radio-box mb-3">
                            <div className="search-filter w-100">
                                <div className="row row-gap-3 align-items-center mt-3" >
                                {segments.map((segment, index) => (
                                  <>
                                        <div className="col-lg-6 col-12" key={index}>
                                            <div className="double-box city-double">
                                                <SelectCityComponent
                                                    label={'From'}
                                                    placeholder={"From ?"}
                                                    cityId={segment.origin}
                                                    cityName={segment.originCity}
                                                    airportName={segment.originAirportName}
                                                    onSelect={(data) => {
                                                        events._updateSegmentState(index, {
                                                            origin: data.value,
                                                            originCity: data.cityName,
                                                            originAirportName: data.airportName,
                                                        });
                                                    }}
                                                />
                                                <div className="divide-box position-relative">
                                                    <img src={swapIcon} alt="Shift Icon"/>
                                                </div>
                                                <SelectCityComponent
                                                    label={'To'}
                                                    style={{paddingLeft: 40}}
                                                    placeholder={"To ?"}
                                                    cityId={segment.destination}
                                                    cityName={segment.destinationCity}
                                                    airportName={segment.destinationAirportName}
                                                    onSelect={(data) => {
                                                        events._updateSegmentState(index, {
                                                            destination: data.value,
                                                            destinationCity: data.cityName,
                                                            destinationAirportName: data.airportName,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-12">
                                            <div className="double-box">
                                                <ChooseDateComponent
                                                    placeholder={'Departure Date'}
                                                    journeyType={journeyType}
                                                    value={segment.preferredDepartureTime}
                                                    label={'Departure'}
                                                    searchResults={calendarFareData.departure}
                                                    callback={events.callbackCalendarFxn}
                                                    state={state}
                                                    disabledDate={(current) => disabledDate(current, moment())}
                                                    onChange={(value) => {
                                                        events._updateSegmentState(index, { preferredDepartureTime: value });
                                                    }}/>


                                            </div>
                                        </div>
                                  </>

                                ))}
                                {segments.length < 5 && (
                                    <div className="col-lg-2 col-12">
                                        <span onClick={addSegment} className="btn btn-dark">
                                            + Add City
                                        </span>
                                        {segments.length > 2 && (
                                        <span onClick={removeLastSegment} className={'ps-2'}>
                                             <img src={DeleteIcon} alt={'img'} width={25}/>
                                        </span>
                                        )}
                                    </div>

                                )}

                                <div className="col-lg-3 col-12">
                                    <div className="double-box">
                                        <TravellersComponent
                                            state={state}
                                            onChange={(data) => {
                                                events._updateState(data)
                                            }}
                                            resultFareType={state.resultFareType}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6  col-12">
                                    <FareTypesNewComponent value={state.resultFareType} onClick={(data) => {
                                        events._updateState({resultFareType: data})
                                    }}/>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button onClick={() => {
                    events.handleSearchFlight()
                }} className="btn btn-primary mt-3 w-100 search-btn">
                    Search Flights
                </button>
            </Form>
        </>
    )
}

export default MultiCitySearchComponent
