import React, {useEffect, useState} from "react";
import RegisterBanner from "../../components/pageComponents/registerBanner";
import {searchFlightFxn} from "../flightSearch/actions";
import {useDispatch, useSelector} from "react-redux";
import {calculateCheapestAndFastestFlights, groupFlightsFxn,} from "./commonFilterFxns";
import FlightFilters from "./components/flightFilters";
import SortFlightFilters from "./components/sortFlightFilters";
import {Tabs, Skeleton} from "../../components/Elements";
import RoundFlightDetailsBox from "./roundTrip/roundFlightDetailsBox";
import RoundTripBook from "./roundTrip/roundTripBook";
import OverseasFlightAddress from "./flightListcomponents/overseasflightDetailsBox";
import OneWayFlightDetailsBox from "./flightListcomponents/oneWayFlightDetailsBox";
import ModifySearchComponent from "../flightSearch/modifySearchComponent";
import {searchPatternBg} from "../../components/imgComp";
import {calculateNewFareWithMargins, updatePriceUpdate} from "../../components/utils/appUtils";
import PlaneSliderComponent from "../checkout/planeSliderComponent";
import MultiCityFlightDetails from "./flightListcomponents/multiCityFlightDetails";
import MultiCityModifySearch from "../flightSearch/modifySearchComponent/multiCityModifySearch";

let $ = window.$;
let refundFilterList = ['Non Refundable', 'Refundable']
const initRangeState = {
    min: 0, max: 0
}
const FindFlight = () => {
        const dispatch = useDispatch();
        const {flightObj} = useSelector((state) => ({
            flightObj: state.flightsReducer.flightObj
        }))
        const [flightObjState, setFlightObjState] = useState(flightObj)
        const [flightList, setFlightList] = useState([])
        const [sortedFlights, setSortedFlights] = useState([]);
        const [inboundPriceRange, setInboundPriceRange] = useState(initRangeState);
        const [outboundPriceRange, setOutboundPriceRange] = useState(initRangeState);

        const [inBoundFlightList, setInBoundFlightList] = useState([])
        const [inBoundSortedFlights, setInBoundSortedFlights] = useState([]);

        const [flightInfo, setFlightInfo] = useState({})
        const [showModifySearch, setShowModifySearch] = useState(false)
        const [selectedInboundFlight, setSelectedInboundFlight] = useState(null);
        const [selectedOutboundFlight, setSelectedOutboundFlight] = useState(null);
        const [isMount, setIsMount] = useState(false);
        const [journeyType, setJourneyType] = useState(flightObj.journeyType)
        const [isSticky, setIsSticky] = useState(false);
        console.log(flightObj, "fliht---obj");

        const handleFlightSelect = (flight, type) => {
            if (type === "inBound") {
                setSelectedInboundFlight(flight);
            } else if (type === "outBound") {
                setSelectedOutboundFlight(flight);
            }
        };
        useEffect(() => {
            dispatch({type: "RESET_SSR_RESPONSE"});
            window.scrollTo(0, 0)
        }, [])

        useEffect(() => {
            const handleScroll = () => {
                const scrollPos = window.scrollY;
                const innerLength = document.getElementById("filter-inner-hidden").offsetHeight;
                console.log(innerLength);
                if ((scrollPos + 150) > innerLength) {
                    setIsSticky(true);
                } else {
                    setIsSticky(false);
                }
            };

            window.addEventListener("scroll", handleScroll);
            return () => window.removeEventListener("scroll", handleScroll);
        }, []);


        const events = {
            searchFlight: async (flightQuery) => {
                setIsMount(false)
                let {data, info, inBoundFlightList, marginData} = await dispatch(searchFlightFxn(flightQuery));
                data = await updatePriceUpdate(data, marginData)
                inBoundFlightList = await updatePriceUpdate(inBoundFlightList, marginData)

                setFlightObjState(flightQuery)
                setFlightList(data)
                setInBoundFlightList(inBoundFlightList)
                events.filterOutBoundFlight(data)
                if (data && data.length) {
                    const {cheapestFlight, mostExpensiveFlight} = calculateCheapestAndFastestFlights(data);
                    setOutboundPriceRange({
                        min: cheapestFlight,
                        max: mostExpensiveFlight,
                    })
                }
                if (inBoundFlightList && inBoundFlightList.length) {
                    const {cheapestFlight, mostExpensiveFlight} = calculateCheapestAndFastestFlights(inBoundFlightList);
                    setInboundPriceRange({
                        min: cheapestFlight,
                        max: mostExpensiveFlight,
                    })
                }
                events.filterInBoundFlight(inBoundFlightList);
                setJourneyType(flightQuery.journeyType)
                setFlightInfo((prevState) => {
                    return {
                        ...prevState,
                        ...info
                    }
                })
                setIsMount(true)
            },
            filterOutBoundFlight: (data) => {
                let groupedData = groupFlightsFxn(data);
                // console.log(groupedData, "gorippp---out")
                setSortedFlights(groupedData)
                if ((!selectedOutboundFlight || (selectedOutboundFlight && !selectedOutboundFlight['ResultIndex'])) && groupedData && groupedData.length) {
                    let {flights = []} = groupedData[0] || []
                    setSelectedOutboundFlight(flights[0])
                } else if (flightList?.length) {
                    let {flights = []} = groupedData[0] || []
                    setSelectedOutboundFlight(flights[0])
                }
            },
            filterInBoundFlight: (data) => {
                let groupedData = groupFlightsFxn(data);
                setInBoundSortedFlights(groupedData)
                // console.log(groupedData, "gorippp--in")
                if ((!selectedInboundFlight || (selectedInboundFlight && !selectedInboundFlight['ResultIndex'])) && groupedData && groupedData.length) {
                    let {flights = []} = groupedData[0] || []
                    setSelectedInboundFlight(flights[0])
                } else if (inBoundFlightList?.length) {
                    let {flights = []} = groupedData[0] || []
                    setSelectedInboundFlight(flights[0])
                }
            },
        }

        const toggleModifySearch = () => {
            setShowModifySearch((prev) => !prev);
        };

        // useEffect(() => {
        //     if (flightObj?.PNR) {
        //         events.reissueTicket(flightObj);
        //     } else {
        //         events.searchFlight(flightObj);
        //     }
        // }, []);

        useEffect(() => {
            events.searchFlight(flightObj)
        }, [])

        const tabItems = [
            {
                key: '1',
                label: 'Outbound',
                children: (
                    <>
                        <FlightFilters
                            title={'Outbound'}
                            flightList={flightList}
                            refundFilterList={refundFilterList}
                            priceRange={outboundPriceRange}
                            setSortedFlights={events.filterOutBoundFlight}
                        />
                    </>
                ),
            },
            {
                key: '2',
                label: 'Inbound',
                children: (
                    <>
                        <FlightFilters
                            title={'Inbound'}
                            priceRange={inboundPriceRange}
                            flightList={inBoundFlightList}
                            refundFilterList={refundFilterList}
                            setSortedFlights={events.filterInBoundFlight}
                        />
                    </>
                ),
            },
        ]

        const getPassengerValue = (state) => {
            let valueD = []
            if (state.adultCount) {
                valueD.push(`${state.adultCount} Adult(s)`)
            }
            if (state.childCount) {
                valueD.push(`${state.childCount} Child(s)`)
            }
            if (state.infantCount) {
                valueD.push(`${state.infantCount} Infant(s)`)
            }
            return valueD.join(', ')
        }

        const onWayFlightList = (
            <>

                <SortFlightFilters
                    flightList={flightList}
                    refundFilterList={refundFilterList}
                    setSortedFlights={events.filterOutBoundFlight}/>
                {sortedFlights && sortedFlights.length ?
                    sortedFlights.map((item) => {
                        return (
                            <>
                                <OneWayFlightDetailsBox flightInfo={flightInfo} data={item.flights}/>
                            </>
                        )
                    }) : null}
            </>
        )
        const returnFlightList = (
            <>
                <div className="divide-book-flight sticky-check-box">
                    <div className="d-flex mb-3">
                        <div className="col-md-12">
                            <RoundTripBook flightInfo={flightInfo}
                                           mappedInboundFlights={selectedInboundFlight}
                                           mappedOutboundFlights={selectedOutboundFlight}
                            />
                        </div>
                    </div>
                </div>
                <div className="divide-book-flight">
                    <div className="row row-gap-3 mb-3">
                        <div className="col-lg-6">
                            <div
                                className="d-flex flex-wrap gap-3 align-items-center mb-3">
                                <SortFlightFilters
                                    flightList={flightList}
                                    refundFilterList={refundFilterList}
                                    setSortedFlights={events.filterOutBoundFlight}
                                />
                            </div>
                            <div className={'scrolled-box'}>
                                {sortedFlights && sortedFlights.length ? sortedFlights.map((item) => {
                                    let {flights} = item;
                                    return (
                                        <RoundFlightDetailsBox
                                            flightInfo={flightInfo}
                                            data={flights}
                                            type={'outBound'}
                                            flightData={selectedOutboundFlight}
                                            onFlightSelect={(data) => {
                                                handleFlightSelect(data, 'outBound')
                                            }}/>
                                    )
                                }) : null}
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="d-flex flex-wrap gap-3 align-items-center mb-3">
                                <SortFlightFilters
                                    flightList={inBoundFlightList}
                                    refundFilterList={refundFilterList}
                                    setSortedFlights={events.filterInBoundFlight}
                                />
                            </div>
                            <div className={'scrolled-box'}>
                                {inBoundSortedFlights && inBoundSortedFlights.length ? inBoundSortedFlights.map((item) => {
                                    let {flights} = item;
                                    return (
                                        <RoundFlightDetailsBox
                                            flightInfo={flightInfo}
                                            type={'inBound'}
                                            data={flights}
                                            flightData={selectedInboundFlight}
                                            onFlightSelect={(data) => {
                                                handleFlightSelect(data, 'inBound')
                                            }}/>
                                    )
                                }) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
        const overseasFlightList = (
            <>

                <div className="">
                    <div className="row row-gap-3 mb-3">
                        <div className="col-lg-12">
                            <div
                                className="d-flex flex-wrap gap-3 align-items-center mb-3">
                                <SortFlightFilters
                                    flightList={flightList}
                                    refundFilterList={refundFilterList}
                                    setSortedFlights={events.filterOutBoundFlight}
                                />
                            </div>

                            <div className={'scrolled-box'}>
                                {sortedFlights && sortedFlights.length ?
                                    sortedFlights.map((item) => {
                                        return (
                                            <>
                                                <OverseasFlightAddress flightInfo={flightInfo} data={item.flights[0]}/>
                                            </>
                                        )
                                    }) : null}
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    const multiCityFlightList = (
        <>
            <SortFlightFilters
                flightList={flightList}
                refundFilterList={refundFilterList}
                setSortedFlights={events.filterOutBoundFlight}
            />
            {sortedFlights && sortedFlights.length ? (
                sortedFlights.map((item) => (
                    <>
                        <MultiCityFlightDetails flightInfo={flightInfo} data={item.flights} />
                    </>
                ))
            ) : null}
        </>
    );

    const filterBoxComponentInner = (
        <>
            {journeyType === "2" && inBoundFlightList && inBoundFlightList.length ?
                <Tabs
                    tabPosition="top"
                    items={tabItems}
                    defaultActiveKey="1"
                    className={'w-100'}
                /> :
                <FlightFilters
                    flightList={flightList}
                    priceRange={outboundPriceRange}
                    refundFilterList={refundFilterList}
                    setSortedFlights={events.filterOutBoundFlight}
                />}
        </>
    )

    const filterBoxComponent = (
        <>
            <div id={'filter-box'} className={isSticky ? "filter-sticky-box" : ""}>
                <div id={'filter-inner'} className={'filter-inner'}>
                    {filterBoxComponentInner}
                </div>
                {isSticky ?
                    <div className={'btn btn-outline-dark mt-1 w-100'} onClick={() => {
                        window.scrollTo(0, 0)
                    }}>
                        Scroll To Top
                    </div> : null}
                <div id={'filter-inner-hidden'} style={{visibility: "hidden"}}>
                    {filterBoxComponentInner}
                </div>
            </div>
        </>
    )

    const totalFlightBlock = (
        <>
            {journeyType === "2" ? inBoundFlightList && inBoundFlightList.length ? sortedFlights.length : flightList.length : sortedFlights.length}
        </>
    )


    return (
        <>

                <div className="find-flight">
                    <div className="flight-top-filters spacing-20 sticky-modify-box"
                         style={{backgroundImage: `url(${searchPatternBg})`}}>
                        {journeyType === '3' ? (
                            <MultiCityModifySearch
                                flightList={flightList}
                                flightObj={flightObj}
                                flightSearch={(resp) => {
                                    events.searchFlight(resp);
                                }}
                            />
                        ) : (
                            <ModifySearchComponent
                                flightList={flightList}
                                flightObj={flightObj}
                                flightSearch={(resp) => {
                                    events.searchFlight(resp);
                                }}
                            />
                        )}

                        <div className="container" style={{height: 50, padding: "0 40px 0 20px"}}>
                            {!isMount ? <PlaneSliderComponent/> : null}
                        </div>
                    </div>


                    <div className="result-flights spacing-20 bg-gray">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-3">
                                    {filterBoxComponent}
                                </div>
                                <div className="col-lg-9">
                                    <div className="find-flight-main">
                                        <div className="d-flex flex-wrap gap-3 align-items-center mb-3">
                                            <h5 className="m-0">
                                                {(isMount && inBoundFlightList.length === 0) ?
                                                    <span>{totalFlightBlock} Available Flights</span> : null}
                                            </h5>
                                            {/*    <ul className="ms-auto p-0 d-flex gap-2 mb-0 align-items-center">
                                            <li>Share By:</li>
                                            <li>
                                                <img
                                                    src="/images2/whatsapp-icon.png"
                                                    width="28"
                                                    alt="whatsapp-icon"
                                                />
                                            </li>
                                            <li>
                                                <img
                                                    src="/images2/gmail-icon.png"
                                                    width="28"
                                                    alt="gmail-icon"
                                                />
                                            </li>
                                            <li>
                                                <img
                                                    src="/images2/eye-icon.png"
                                                    width="28"
                                                    alt="eye-icon"
                                                />
                                            </li>
                                        </ul>*/}
                                        </div>
                                        <div>
                                            {isMount ? <div>
                                                    {sortedFlights && sortedFlights.length > 0 ?
                                                        <>
                                                            {journeyType === "1" ? (
                                                                onWayFlightList
                                                            ) : journeyType === "2" ? (
                                                                inBoundFlightList && inBoundFlightList.length ? returnFlightList : overseasFlightList
                                                            ) : journeyType === "3" ? (
                                                                multiCityFlightList
                                                            ) : null}

                                                        </> :
                                                        <div className="head text-center mb-5 p-5">
                                                            <h5>No flights found that match your filter criteria.</h5>
                                                        </div>
                                                    }
                                                </div> :
                                                <div className="head text-center mb-5">
                                                    <Skeleton rows={7}/>
                                                    <br/>
                                                    <Skeleton rows={7}/>
                                                    <br/>
                                                    <Skeleton rows={7}/>
                                                    <br/>
                                                    <Skeleton rows={7}/>
                                                    <br/>
                                                    <Skeleton rows={7}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <RegisterBanner/>
            </>
        );
    }
;

export default FindFlight;
