import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    displayDate,
    getAirlineLogo,
    getTotalDuration,
    getTotalDurationSegments,
    minToHours
} from "../../components/utils/appUtils";
import FlightImg from "../../components/flightImg";
import AllStopsDetailsPopover from "./components/AllStopsDetailsPopover";
import LccStatus from "./components/LccStatus";


const FlightAddress = ({data}) => {
    return (
        <>
            <small className="d-block">
                {/*{data && data.Airport && data.Airport.AirportName ? data.Airport.AirportName : ""}*/}

            </small>
            <small className="d-block">
                {/*{data && data.Airport && data.Airport.Terminal ? `${data.Airport.Terminal}, ` : ""}*/}
                {data && data.Airport && data.Airport.CityName ? `${data.Airport.CityName} ` : ""}
                {data && data.Airport && data.Airport.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}

                {/*{data && data.Airport && data.Airport.CountryName ? data.Airport.CountryName : ""}*/}
            </small>
        </>
    )
}
const NonStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    return (
        <>
            <div className={''}>
                <ul>
                    <li className="p-0">
                          <span className="logo">
                            <img src={getAirlineLogo(data.AirlineCode)} alt=""/>
                          </span>
                        <h5>
                            {segmentStarting && segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                            {segmentStarting ? <small className="d-block">
                                {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                            </small> : null}
                            <LccStatus data={data}/>
                        </h5>
                        <div className="d-flex align-items-center" style={{gap: 30}}>
                            <h5>
                                {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                    {displayDate(segmentStarting.Origin.DepTime)}
                                </> : null}
                                {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                            </h5>
                            <span><FlightImg/></span>
                            <h5>
                                <small className="d-block">
                                    {getTotalDurationSegments(allSegmentData)}
                                </small>
                                <small className={"d-block"}>
                                    <p className={'non-stop-p'}>Non-Stop</p>

                                </small>

                            </h5>
                            <span><FlightImg/></span>

                            <h5>
                                {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                    {displayDate(segmentEnding.Destination.ArrTime)}
                                </> : null}
                                {segmentEnding.Destination ?
                                    <FlightAddress data={segmentEnding.Destination}/> : null}
                            </h5>
                            <h5>
                                {getTotalDurationSegments(allSegmentData)}
                                <small className="d-block">Economy Class</small>
                                <small className="d-block">
                                    {data.IsRefundable ? "Refundable" : "Non Refundable"}
                                </small>
                            </h5>
                        </div>
                    </li>
                    <p className="line">
                        Adult: Check-in (
                        <span style={{ color: segmentStarting?.Baggage === '0' ? 'red' : 'inherit' }}>
                            {segmentStarting?.Baggage}
                        </span>) + Cabin: {segmentStarting?.CabinBaggage}
                    </p>

                </ul>
            </div>
        </>
    )
}
const CheckoutWithStopComponent = ({data}) => {
    const {Segments} = data;
    const allSegmentData = Segments[0];
    console.log(Segments, "---------")

    const calculateLayoverTime = (arrivalTime, departureTime) => {
        if (!arrivalTime || !departureTime) return "";
        const arr = new Date(arrivalTime);
        const dep = new Date(departureTime);
        return minToHours(Math.max(0, Math.floor((dep - arr) / 60000)));
    };

    return (
        <>
            {allSegmentData.map((segment, index) => {
                const isLastSegment = index === allSegmentData.length - 1;
                const nextSegment = allSegmentData[index + 1];
                const layoverTime = nextSegment
                    ? calculateLayoverTime(segment.Destination?.ArrTime, nextSegment.Origin?.DepTime)
                    : null;
                return (
                    <React.Fragment key={index}>
                        <div className="track-flights false mt-3">
                            <ul>
                                <li className="p-0">
                              <span className="logo">
                              <img src={getAirlineLogo(segment.Airline?.AirlineCode)} alt=""/>
                             </span>
                                    <h6>
                                        {segment.Airline?.AirlineName}
                                        <small className="d-block">
                                            {segment.Airline?.AirlineCode} - {segment.Airline?.FlightNumber} {segment.Airline?.FareClass}
                                        </small>
                                        <small className="d-block">{data.IsLCC ? "LCC" : "Non LCC"}</small>
                                    </h6>

                                    <div className="d-flex align-items-center" style={{gap: "30px"}}>
                                        <h6>
                                            {segment.Origin?.DepTime && <>{displayDate(segment.Origin.DepTime)}</>}
                                            {segment.Origin && <FlightAddress data={segment.Origin}/>}
                                        </h6>

                                        <span>
                                          <FlightImg/>
                                        </span>
                                        <h6>


                                            <small className="d-block">
                                                {segment.Origin?.Airport?.AirportCode} &nbsp;-&nbsp; {segment.Destination?.Airport?.AirportCode}
                                            </small>
                                            <small className="d-block">
                                                {minToHours(segment['Duration'])}
                                            </small>

                                        </h6>
                                        <span>
                                          <FlightImg/>
                                        </span>

                                        <h6>
                                            {segment.Destination?.ArrTime && <>{displayDate(segment.Destination.ArrTime)}</>}
                                            {segment.Destination &&
                                            <FlightAddress data={segment.Destination} segment={segment}/>}
                                        </h6>

                                        {allSegmentData.length === 1 && (
                                            <h6>
                                                {minToHours(segment.Duration)}
                                                <small className="d-block">Economy Class</small>
                                                <small
                                                    className="d-block">{data.IsRefundable ? "Refundable" : "Non Refundable"}</small>
                                            </h6>
                                        )}
                                    </div>
                                </li>
                                <p className="line">
                                    Adult: Check-in (
                                    <span style={{ color: segment?.Baggage === '0' ? 'red' : 'inherit' }}>
                            {segment?.Baggage}
                        </span>) + Cabin: {segment?.CabinBaggage}
                                </p>

                            </ul>
                        </div>
                        {segment.IsTransitVisaRequired ?
                            <div className="transit-visa-required-box">
                                <div className="line"></div>
                                <div className="box">
                                    <span>Transit Visa Required</span>
                                    <small> You will require a transit visa</small>
                                </div>
                            </div> : null}
                        {layoverTime && (
                            <p className="note layover-text">
                                <span
                                    className={'color-red'}>Require to change Plane - Layover Time: {layoverTime} </span>
                            </p>
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};
const MultiWayFlightInfoBox = (props) => {
    let { data, journeyType, title, bookingType = "", sessionId = "" } = props;
    console.log(data, "Multi-City Flight Data");

    let { Segments } = data;
    let allSegmentData = Segments;

    console.log(allSegmentData, "all segments-----");

    let checkWhichProcess = journeyType == "2" ? bookingType == title : true;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const checkoutInBound = () => {
        navigate(`/checkout`, { state: { params: { bookingType: "Inbound", sessionId } } });
    };

    return (
        <div className={`flight-info-box`}>
            {!checkWhichProcess ? <div className={'disabled-block'}></div> : null}

            {sessionId && title == "Inbound" && !checkWhichProcess ? (
                <div className={'book-flight-block'}>
                    <a onClick={checkoutInBound}>Click here to Inbound booking</a>
                </div>
            ) : null}

            <div className={`track-flights ${!checkWhichProcess && "opacity-75"}`}>
                {journeyType == "2" ? (
                    <div>
                        <div className={'flight-type-box'}>
                            <h5>{title}</h5>
                            <span>{checkWhichProcess ? "In Process" : ""}</span>
                        </div>

                        {allSegmentData.map((segmentGroup, index) => {
                            const segmentProps = { ...props, segmentGroup };

                            return (
                                <div key={index}>
                                    {segmentGroup.length > 1 ? (
                                        <CheckoutWithStopComponent {...segmentProps} />
                                    ) : (
                                        <NonStopComponent {...segmentProps} />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div>
                        {allSegmentData.map((segmentGroup, index) => {
                            const segmentProps = { ...props, segmentGroup };

                            return (
                                <div key={index}>
                                    {segmentGroup.length > 1 ? (
                                        <CheckoutWithStopComponent {...segmentProps} />
                                    ) : (
                                        <NonStopComponent {...segmentProps} />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MultiWayFlightInfoBox;


