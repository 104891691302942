import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import commonFxns from "../../components/commonFxns";
// import blackHeader from "../../assets/img/black-header.png";
import countryFlagList from "../../../src/components/Elements/PhoneInput/countryFlag.json"
import currencyList from "../../assets/currencyList.json"
import {getWalletBalanceFxn} from "../../containers/flightSearch/actions";
import {getUser} from "../../containers/authications/action";
import {defaultImg} from "../../components/imgComp"
import {getUserData} from "../../request";
import {useSelector} from 'react-redux'
import flightsReducer from "../../actions/flightsReducer";
import {managerInfo, fixed2Digit} from "../../components/utils/appUtils";

const languageList = [
    {name: "English", code: "en"},
    {name: "Deutsch", code: "de"},
]

const ManagerComponent = () => {
    return (
        <>
            <a
                className="nav-link d-flex align-items-center gap-2 dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <img src="../images2/manager-icon.png" alt="manager-icon" width="25"
                     className={'filter-icon'}/>Your 24/7 Manager
            </a>
            <ul className="dropdown-menu manage-box">
                <div className="d-flex gap-2"
                     style={{paddingBottom: "10px"}}>
                    {/*borderBottom: "1px solid #d0d8e0"*/}
                    <span className="pic m-0">
                                            <img src={defaultImg} alt="Manager"/>
                                        </span>
                    <h5 className="m-0">
                        {managerInfo.name}
                        <span className="d-block mt-1">{managerInfo.mobile}</span>
                        <span className="d-block mt-1">{managerInfo.email}</span>
                    </h5>
                </div>
                <li>
                    <NavLink className="dropdown-item red" to="#">
                        Schedule a Call with Expert
                    </NavLink>
                </li>
                <li>
                    <NavLink className="dropdown-item" to="#">
                        Raise a Complaint
                    </NavLink>
                </li>
                <li>
                    <NavLink className="dropdown-item" to="#">
                        Request a Portal Training
                    </NavLink>
                </li>
            </ul>

        </>
    )
}

const CountryFlagComponent = (props) => {
    let {value} = props;
    return (
        <li className="nav-item dropdown ">
            <a className="nav-link d-flex align-items-center gap-2 dropdown-toggle" href="#"
               role="button"
               data-bs-toggle="dropdown"
               aria-expanded="false">
                🇮🇳
            </a>
            <div className={'dropdown-menu country-flag-list-box'}>
                <h6 className={'title'}>
                    Country/Region
                </h6>
                <ul>
                    {countryFlagList.map((item) => {
                        return (
                            item.name && <li className={`${item.isoCode == value ? "active" : ""}`}
                                             key={`${item.isoCode}-${item.name}`}>
                                {item.emoji} &nbsp;{item.name}
                            </li>
                        )
                    })}
                </ul>
            </div>

        </li>
    )
}
const LanguageFlagComponent = (props) => {
    let {value} = props;
    return (
        <li className="nav-item dropdown ">
            <a className="nav-link d-flex align-items-center gap-2 dropdown-toggle" href="#"
               role="button"
               data-bs-toggle="dropdown"
               aria-expanded="false">
                EN
            </a>
            <div className={'dropdown-menu country-flag-list-box'}>
                <h6 className={'title'}>
                    Language
                </h6>
                <ul>
                    {languageList.map((item) => {
                        return (
                            item.name && <li className={`${item.code == value ? "active" : ""}`} key={item.code}>
                                <b>{item.code}</b> &nbsp;{item.name}
                            </li>
                        )
                    })}
                </ul>
            </div>

        </li>
    )
}
const CurrencyListComponent = (props) => {
    let {value} = props;
    return (
        <li className="nav-item dropdown">
            <a className="nav-link d-flex align-items-center gap-2 dropdown-toggle" href="#"
               role="button"
               data-bs-toggle="dropdown"
               aria-expanded="false">
                INR
            </a>
            <div className={'dropdown-menu country-flag-list-box'}>
                <h6 className={'title'}>
                    Currency
                </h6>
                <ul>
                    {currencyList.map((item) => {
                        return (
                            item.name_plural && <li className={`${item.code == value ? "active" : ""}`} key={item.code}>
                                <b className={'currency-code'}>{item.code}</b>&nbsp;{item.name_plural}
                            </li>
                        )
                    })}
                </ul>
            </div>

        </li>
    )
}
const LoginHeader = (props) => {
    let {currentUser, dispatch} = props;
    let [loginUser, setLoginUser] = useState({})
    let {pathname} = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    let [walletBalance, setWalletBalance] = useState(0)
    const events = {
        loadBalance: async () => {
            let {success, xTravelWallet, data} = await dispatch(getWalletBalanceFxn());
            if (success) {
                setWalletBalance(xTravelWallet);
                setLoginUser(data)
            }
        },
        loadUserEvent: async () => {
            let userData = await getUserData()
            if (userData && userData._id) {
                let {success, data} = await dispatch(getUser());
                if (success) {
                    setLoginUser(data)
                }
            }
        }
    }
    useEffect(() => {
        events.loadBalance()
    }, [])


    const isBalanceLoading = useSelector((state) => state.global.isBalanceLoading);

    useEffect(() => {
        if (isBalanceLoading && currentUser && currentUser._id) {
            events.loadBalance()
        }
    }, [isBalanceLoading])


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    let isSearchFlight = pathname == "/flight-search"
    // let logoUrl = isSearchFlight ? "../images/logo.png" : "../images2/x-travel-landscape.png"
    let logoUrl = "../images/logo.png"
    // let scrollClass = isSearchFlight ? "fixed-header" : "fixed-header white"
    let scrollClass = isSearchFlight ? "fixed-header" : "fixed-header"
    let userImage = loginUser && loginUser.logo && loginUser.logo.url ? loginUser.logo.url : defaultImg;
    let picStyle = loginUser && loginUser.logo && loginUser.logo.url ? "" : "default-img"
    return (
        <>
            <header className={`login-header ${isSearchFlight ? "" : 'dashboard'}`}>
                {/*{isSearchFlight ? <img src={blackHeader} className={'header-main'}/> : ""}*/}
                {isSearchFlight ? <div className={'header-main header-black'}/> : ""}
                {/* Fixed navbar */}
                <nav className={`navbar navbar-expand-md navbar-dark fixed-top ${isScrolled ? scrollClass : ""}`}
                     style={{background: isSearchFlight ? "transparent" : ""}}>
                    <div className="container-fluid">
                        <NavLink className="navbar-brand" to="/flight-search">
                            <img src={logoUrl} alt="logo" className={'x-logo'}/>
                        </NavLink>
                        <button
                            className="navbar-toggler collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse"
                            aria-controls="navbarCollapse"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        ><span className="navbar-toggler-icon"/>
                        </button>
                        <div className="navbar-collapse collapse" id="navbarCollapse">
                            <ul className={`navbar-nav ms-auto mb-2 mb-md-0 align-items-center ${isSearchFlight && ""}`}>
                                {/*white*/}
                                <li className="nav-item">
                                    <NavLink to={'/ticket-list'} style={{textDecoration: "none"}}>
                                            <span>
                                    <div className={`pic ${picStyle}`}>
                                        <img src={userImage}
                                             alt="User"/>
                                    </div>
                                    <b style={{fontWeight: 500}}>{currentUser && currentUser.name ? currentUser.name : ""}</b>
                                </span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                <span className="d-flex align-items-center gap-2">
                                    <img src="../images2/wallet-color-icon.png" width="25" alt="wallet-icon"/>
                                    My Wallet: INR {fixed2Digit(walletBalance)}
                                    <NavLink className="btn p-0 border-0" to={'/add-deposit'}>
                                        <img src="../images2/add-icon.png" alt="add-icon"/>
                                    </NavLink>
                                </span>
                                </li>
                                {/*   <li className="nav-item">
                                <span className="d-flex align-items-center gap-2">
                                    <img src="../images2/wallet-icon.png" alt="wallet-icon" width="25" className={'filter-icon'}/>
                                    My Cash: 100.00
                                </span>
                            </li>*/}
                                <li className="nav-item dropdown">
                                    <ManagerComponent/>

                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link"
                                        // to={currentUser.userType == "branchUser" ? "/registered-agents" : "/ticket-list"}
                                             to={"/ticket-list"}>
                                        Dashboard
                                    </NavLink>
                                </li>

                                <li>
                                    <ul className={'header-group-btn'}>
                                        <CountryFlagComponent value={"IN"}/>
                                        <LanguageFlagComponent value={'en'}/>
                                        <CurrencyListComponent value={'INR'}/>
                                    </ul>
                                </li>

                                <li className="nav-item cursor-pointer" onClick={() => commonFxns.logout(dispatch)}>
                                    Log Out
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default LoginHeader;
